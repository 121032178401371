import React, { useEffect, useState } from 'react';
import { Endpoints, EventStream } from 'Roblox';
import { createModal } from 'react-style-guide';
import { authenticatedUser } from 'header-scripts';
import classNames from 'classnames';
import { UserProfileField, writeQuery } from 'roblox-user-profiles';
import ProfileDropdown from '../components/ProfileDropdown';
import constants from '../constants/profileHeaderConstants';
import authService from '../services/authService';
import contactsService from '../services/contactsService';
import friendsService from '../services/friendsService';
import useProfileHeaderContext from '../hooks/useProfileHeaderContext';
import { ActionType } from '../store/action';
import friendStatusEnums from '../enums/FriendStatus';

const [EditAliasModal, modalService] = createModal();

const ProfileDropdownContainer = ({
  profileUserId,
  translate
}: {
  profileUserId: number;
  translate: (key: string) => string;
}): JSX.Element => {
  const { state, dispatch } = useProfileHeaderContext();
  const { id } = authenticatedUser;
  const isMyProfile = profileUserId === id;
  const isAuthenticated = id !== -1;
  const loginUrl = Endpoints.getAbsoluteUrl('/login');
  const inventoryUrl = Endpoints.getAbsoluteUrl(`/users/${profileUserId}/inventory/`);
  const favoritesUrl = Endpoints.getAbsoluteUrl(`/users/${profileUserId}/favorites#!/places`);
  const homeUrl = Endpoints.getAbsoluteUrl('/home');
  // no api exists for this currently, still have to pull from the data attributes for now
  const mayImpersonate =
    document.querySelector('div[data-mayimpersonate]')?.getAttribute('data-mayimpersonate') ===
    'true';

  const [currentName, setCurrentName] = useState<string>('');
  const [textCount, setTextCount] = useState<number>(0);
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const fireCustomNameModalOpened = () => {
    EventStream.SendEventWithTarget(
      constants.eventNames.modalOpen,
      constants.eventCtx.customizeName,
      {
        origin: 'userProfile',
        playerId: profileUserId.toString()
      },
      EventStream.TargetTypes.WWW
    );
  };

  const fireCustomNameModalClosed = () => {
    EventStream.SendEventWithTarget(
      constants.eventNames.buttonClick,
      constants.eventCtx.customizeName,
      {
        btn: constants.eventBtns.closeCustomName,
        playerId: profileUserId.toString()
      },
      EventStream.TargetTypes.WWW
    );
  };

  const fireCustomNameSaved = (alias: string) => {
    EventStream.SendEventWithTarget(
      constants.eventNames.buttonClick,
      constants.eventCtx.customizeName,
      {
        btn: constants.eventBtns.saveAlias,
        alias,
        playerId: profileUserId.toString()
      },
      EventStream.TargetTypes.WWW
    );
  };

  const fireCustomNameSaveFailed = (alias: string) => {
    EventStream.SendEventWithTarget(
      constants.eventNames.customNameInvalidInput,
      constants.eventCtx.customizeName,
      {
        alias,
        playerId: profileUserId.toString()
      },
      EventStream.TargetTypes.WWW
    );
  };

  const fireMaxLimitAlias = (alias: string) => {
    EventStream.SendEventWithTarget(
      constants.eventNames.customNameMaxLimit,
      constants.eventCtx.customizeName,
      {
        alias,
        playerId: profileUserId.toString()
      },
      EventStream.TargetTypes.WWW
    );
  };

  const fireClearedAlias = () => {
    EventStream.SendEventWithTarget(
      constants.eventNames.customNameClearedInput,
      constants.eventCtx.customizeName,
      {
        playerId: profileUserId.toString()
      },
      EventStream.TargetTypes.WWW
    );
  };

  // Counts the number of unicode characters (as opposed to the number of unicode code units
  // obtained via `.length`)
  //
  // Note: There is a known issue with niche complex emojis such as 👩‍❤️‍💋‍👩 where they will
  // still counted as multiple characters. One possible solution would be to use `Intl.Segmenter`.
  // See:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/length#description
  // for more information.
  const unicodeLength = (str: string) => Array.from(str).length;

  const resetAliasModal = () => {
    setCurrentName(state.names.alias || '');
    setTextCount(unicodeLength(state.names.alias || ''));
    setHasErrored(false);
  };

  useEffect(() => {
    resetAliasModal();
  }, [state]);

  const openAliasModal = () => {
    modalService.open();
    fireCustomNameModalOpened();
  };

  const closeAliasModal = () => {
    resetAliasModal();
    modalService.close();
    fireCustomNameModalClosed();
    setTimeout(() => {
      (document.activeElement as HTMLElement).blur();
    });
  };

  const userProfileFields = [
    UserProfileField.Names.CombinedName,
    UserProfileField.Names.Username,
    UserProfileField.Names.DisplayName,
    UserProfileField.Names.Alias
  ];

  const setAlias = (alias: string): void => {
    contactsService.setUserTag(profileUserId, alias).then(
      response => {
        if (response.status === 'Success') {
          if (alias === '') {
            writeQuery(profileUserId, userProfileFields, {
              names: {
                alias,
                combinedName: state.names.displayName,
                displayName: state.names.displayName,
                username: state.names.username
              }
            });
          } else {
            writeQuery(profileUserId, userProfileFields, {
              names: {
                alias,
                combinedName: alias,
                displayName: state.names.displayName,
                username: state.names.username
              }
            });
          }
          fireCustomNameSaved(alias);
          closeAliasModal();
        } else {
          fireCustomNameSaveFailed(alias);
          setHasErrored(true);
        }
      },
      () => {
        setHasErrored(true);
      }
    );
  };

  const updateCurrentName = (e: React.FormEvent<HTMLInputElement>) => {
    let newName = e.currentTarget.value;
    let unicodeLen = unicodeLength(newName);
    if (unicodeLen > constants.maxCharactersForAlias) {
      if (textCount === constants.maxCharactersForAlias) {
        return;
      }

      newName = Array.from(newName).slice(0, constants.maxCharactersForAlias).join('');
      unicodeLen = constants.maxCharactersForAlias;
    }

    setCurrentName(newName);
    setTextCount(unicodeLen);

    if (unicodeLen === constants.maxCharactersForAlias) {
      fireMaxLimitAlias(newName);
    }
    if (unicodeLen === 0) {
      fireClearedAlias();
    }
    setHasErrored(false);
  };

  const errorText = hasErrored ? translate(constants.translationKeys.invalidAlias) : '';
  const countdownText = `${textCount}/${constants.maxCharactersForAlias}`;
  const descriptionHeader = `${translate(constants.translationKeys.setCustomName)} `;
  const descriptionText = translate(constants.translationKeys.recognizeFriends);
  const placeHolderText = translate(constants.translationKeys.customizeNamePlaceholder);
  const modalTitle = translate(constants.translationKeys.customizeName);

  const inputClassNames = classNames(`form-group`, {
    'form-has-error': hasErrored,
    'form-has-feedback': true
  });

  const sendFollow = async () => {
    try {
      const follow = await friendsService.followUser(profileUserId);
      if (follow.success) {
        dispatch({ type: ActionType.UPDATE_FOLLOWERS_COUNT, amount: 1 });
        dispatch({ type: ActionType.UPDATE_FOLLOWING, following: true });
      }
    } catch (error) {
      dispatch({
        type: ActionType.SET_ERROR_MESSAGE,
        message: translate(constants.translationKeys.error.followFailed)
      });
    }
  };

  const unFollow = async () => {
    if (!id) {
      window.location.href = loginUrl;
    } else {
      try {
        await friendsService.unFollowUser(profileUserId);
        dispatch({ type: ActionType.UPDATE_FOLLOWERS_COUNT, amount: -1 });
        dispatch({ type: ActionType.UPDATE_FOLLOWING, following: false });
      } catch (error) {
        dispatch({
          type: ActionType.SET_ERROR_MESSAGE,
          message: translate(constants.translationKeys.error.unfollowFailed)
        });
      }
    }
  };

  const impersonateUser = async () => {
    try {
      await authService.impersonateUser(profileUserId);
      window.location.href = homeUrl;
    } catch (error) {
      dispatch({
        type: ActionType.SET_ERROR_MESSAGE,
        message: translate(constants.translationKeys.error.impersonateUserFailed)
      });
    }
  };

  const tradeItems = () => {
    EventStream.SendEventWithTarget(
      constants.eventNames.tradeEntryPoint,
      constants.eventCtx.profileMenu,
      {
        partnerId: profileUserId.toString()
      },
      EventStream.TargetTypes.WWW
    );
    window.location.href = Endpoints.getAbsoluteUrl(`/users/${profileUserId}/trade`);
  };

  const buttons = [
    {
      id: 'customize-name-button',
      label: constants.translationKeys.customizeName,
      visible: state.friendStatus === friendStatusEnums.Friends,
      onClick: openAliasModal
    },
    {
      id: 'follow-button',
      label: constants.translationKeys.dropdown.follow,
      visible: !isMyProfile && !state.isFollowing && !state.isBlocked,
      onClick: sendFollow
    },
    {
      id: 'unfollow-button',
      label: constants.translationKeys.dropdown.unfollow,
      visible: !isMyProfile && state.isFollowing && !state.isBlocked,
      onClick: unFollow
    },
    {
      id: 'trade-button',
      label: constants.translationKeys.dropdown.tradeItems,
      visible: !state.isBlocked && state.canTradeWith,
      onClick: tradeItems
    },
    {
      id: 'block-button',
      label: !state.isBlocked
        ? constants.translationKeys.dropdown.blockUser
        : constants.translationKeys.dropdown.unblockUser,
      visible: !isMyProfile && isAuthenticated,
      onClick: () => {
        dispatch({ type: ActionType.SHOW_BLOCK_USER_MODAL, show: true });
      }
    },
    {
      id: 'inventory-button',
      label: constants.translationKeys.dropdown.inventory,
      visible: profileUserId !== 1,
      onClick: () => {
        window.location.href = inventoryUrl;
      }
    },
    {
      id: 'favorites-button',
      label: constants.translationKeys.dropdown.favorites,
      visible: state.hasFavorites,
      onClick: () => {
        window.location.href = favoritesUrl;
      }
    },
    {
      id: 'impersonate-button',
      label: constants.translationKeys.dropdown.impersonateUser,
      visible: mayImpersonate,
      onClick: impersonateUser
    }
  ].filter(item => item.visible);

  return (
    <React.Fragment>
      <ProfileDropdown translate={translate} buttons={buttons} />
      <EditAliasModal
        title={modalTitle}
        body={
          <div className='change-alias-modal'>
            <div className='text-label'>
              <span>{descriptionHeader}</span>
              <span className='change-alias-bolded'>{state.names.displayName}</span>
            </div>
            <div className='change-alias-description'>{descriptionText}</div>
            <div className={inputClassNames}>
              <input
                className='form-control input-field'
                id='aliasInputBox'
                onChange={updateCurrentName}
                value={currentName}
                placeholder={placeHolderText}
              />
              <div className='clearfix font-caption-body change-alias-feedback-container'>
                <span className='count-down'>{countdownText}</span>
                <span className='form-control-label'>{errorText}</span>
              </div>
            </div>
          </div>
        }
        actionButtonShow
        disableActionButton={hasErrored}
        actionButtonText={translate(constants.translationKeys.save)}
        neutralButtonText={translate(constants.translationKeys.cancel)}
        onNeutral={() => {
          closeAliasModal();
        }}
        onAction={() => {
          setAlias(currentName);
        }}
      />
    </React.Fragment>
  );
};

export default ProfileDropdownContainer;
