import React, { useEffect } from 'react';
import { createModal } from 'react-style-guide';
import { UserProfileField, writeQuery } from 'roblox-user-profiles';
import useProfileHeaderContext from '../hooks/useProfileHeaderContext';
import { ActionType } from '../store/action';
import profileHeaderConstants from '../constants/profileHeaderConstants';
import userBlockingService from '../services/userBlockingService';
import BlockUserError from '../enums/BlockUserError';
import TError from '../types/errorTypes';

const BlockUserModal = ({
  translate,
  profileUserId
}: {
  translate: (key: string) => string;
  profileUserId: number;
}): JSX.Element | null => {
  const { state, dispatch } = useProfileHeaderContext();
  const [BlockModal, modalService] = createModal();
  const hideUserBlockModal = () => {
    dispatch({ type: ActionType.SHOW_BLOCK_USER_MODAL, show: false });
  };

  const blockUser = async () => {
    try {
      await userBlockingService.blockUser(profileUserId);
      dispatch({ type: ActionType.UPDATE_USER_BLOCK, block: true });
      const userProfileFields = [
        UserProfileField.Names.CombinedName,
        UserProfileField.Names.Username,
        UserProfileField.Names.DisplayName,
        UserProfileField.Names.Alias
      ];
      writeQuery(profileUserId, userProfileFields, {
        names: {
          alias: null,
          combinedName: state.names.displayName,
          displayName: state.names.displayName,
          username: state.names.username
        }
      });
    } catch (error) {
      dispatch({
        type: ActionType.SET_ERROR_MESSAGE,
        message: translate(profileHeaderConstants.translationKeys.error.blockFailed)
      });
    }
  };

  const unblockUser = async () => {
    try {
      await userBlockingService.unblockUser(profileUserId);
      dispatch({ type: ActionType.UPDATE_USER_BLOCK, block: false });
    } catch (error) {
      let message = translate(profileHeaderConstants.translationKeys.error.blockRequestFailed);
      switch ((error as TError).data) {
        case BlockUserError.BlockLimitExceeded:
          message = translate(profileHeaderConstants.translationKeys.error.blockLimitExceeded);
          break;
        case BlockUserError.TargetAlreadyBlocked:
          message = translate(profileHeaderConstants.translationKeys.error.targetAlreadyBlocked);
          break;
        case BlockUserError.ParentManaged:
          message = translate(profileHeaderConstants.translationKeys.error.parentManaged);
          break;
        case BlockUserError.TargetNotBlocked:
          message = translate(profileHeaderConstants.translationKeys.error.targetNotBlocked);
          break;
        default:
      }
      dispatch({
        type: ActionType.SET_ERROR_MESSAGE,
        message
      });
    }
  };

  let translations: {
    body: string;
    footer?: string;
    title: string;
    actionButton: string;
    cancelButton: string;
  } = profileHeaderConstants.translationKeys.modal.block;
  if (state.isBlocked) {
    translations = profileHeaderConstants.translationKeys.modal.unblock;
  }

  useEffect(() => {
    if (state.showUserBlockModal) {
      modalService.open();
    } else {
      modalService.close();
    }
  }, [modalService, state.showUserBlockModal]);

  return state.showUserBlockModal ? (
    <BlockModal
      footerText={translations.footer ? translate(translations.footer) : ''}
      title={translate(translations.title)}
      onClose={hideUserBlockModal}
      body={translate(translations.body)}
      actionButtonShow
      actionButtonText={translate(translations.actionButton)}
      onAction={state.isBlocked ? unblockUser : blockUser}
      neutralButtonText={translate(translations.cancelButton)}
      onNeutral={hideUserBlockModal}
    />
  ) : null;
};

export default BlockUserModal;
