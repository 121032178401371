const profileHeaderContainer = (): HTMLElement | null =>
  document.getElementById('profile-header-container');

const translationKeys = {
  friends: 'Label.Friends',
  followers: 'Label.Followers',
  following: 'Label.Following',
  customizeName: 'Label.CustomizeName',
  cancel: 'Label.Cancel',
  setCustomName: 'Description.SetCustomName2',
  recognizeFriends: 'Description.RecognizeFriendsByCustomName',
  save: 'Action.Save',
  customizeNamePlaceholder: 'Label.CustomizeNamePlaceholder',
  invalidAlias: 'Message.InvalidAliasError',
  customName: 'Label.CustomName',
  recognizeTooltip: 'Tooltip.CustomNameProfile',
  buttons: {
    accept: 'Action.Accept',
    addFriend: 'Action.AddFriend',
    pending: 'Action.Pending',
    unFriend: 'Action.Unfriend',
    chat: 'Action.Chat',
    message: 'Action.Message',
    joinGame: 'Action.JoinGame',
    openDropdown: 'Action.SeeMore'
  },
  modal: {
    block: {
      title: 'Heading.BlockUserTitle',
      body: 'Description.BlockUserPrompt',
      actionButton: 'Action.ConfirmBlockUser',
      cancelButton: 'Action.CancelBlockUser',
      footer: 'Description.BlockUserFooter'
    },
    unblock: {
      title: 'Heading.BlockUserTitle',
      body: 'Description.UnblockUserPrompt',
      actionButton: 'Action.ConfirmUnblockUser',
      cancelButton: 'Action.CancelBlockUser'
    }
  },
  dropdown: {
    message: 'Action.Message',
    follow: 'Action.Follow',
    unfollow: 'Action.Unfollow',
    blockUser: 'Action.BlockUser',
    unblockUser: 'Action.UnblockUser',
    inventory: 'Action.Inventory',
    favorites: 'Action.Favorites',
    impersonateUser: 'Action.ImpersonateUser',
    tradeItems: 'Action.TradeItems'
  },
  error: {
    acceptFriendRequestFailed: 'Message.AcceptFriendRequestError',
    sendFriendRequestFailed: 'Message.SendFriendRequestError',
    removeFriendFailed: 'Message.RemoveFriendError',
    followFailed: 'Message.FollowError',
    unfollowFailed: 'Message.UnfollowError',
    blockFailed: 'Message.BlockError',
    blockRequestFailed: 'Message.BlockRequestError',
    impersonateUserFailed: 'Message.ImpersonateUserError',
    updateStatusFailed: 'Message.UpdateStatusError',
    tooManyAttempts: 'Response.TooManyAttempts',
    blockLimitExceeded: 'Message.TooManyBlockedUsersError',
    targetAlreadyBlocked: 'Message.UserAlreadyBlockedError',
    targetNotBlocked: 'Message.TargetNotBlockedError',
    parentManaged: 'Message.ParentBlockedUserError'
  }
};

const eventNames = {
  playerFriendAdd: 'playerFriendAdd',
  playerFriendAccept: 'playerFriendAccept',
  tradeEntryPoint: 'tradeEntryPoint',
  modalOpen: 'modalOpen',
  buttonClick: 'buttonClick',
  customNameInvalidInput: 'customizeNameSaveAttemptInvalidInput',
  customNameMaxLimit: 'customizeNameMaximumCharLimitTyped',
  customNameClearedInput: 'customizeNameClearedInput'
};

const maxCharactersForAlias = 20;

const eventCtx = {
  userProfile: 'userProfile',
  profileMenu: 'profileMenu',
  customizeName: 'customizeName'
};

const eventBtns = {
  saveAlias: 'saveAlias',
  closeCustomName: 'closeCustomizeName'
};

const experiments = {
  profileFRAcceptCTALayer: 'Social.Profile',
  profileFRAcceptCTAParameter: 'useProminentFRAcceptCTA'
};

export default {
  profileHeaderContainer,
  translationKeys,
  eventNames,
  eventCtx,
  maxCharactersForAlias,
  eventBtns,
  experiments
};
